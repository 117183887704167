import { CartSuccessIcon } from 'assets';
import { Container } from 'components';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { AuthThunks } from 'modules/authentication';
import { ApplicationState } from 'modules/redux-store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buttons, cart } from 'styles';

const CartSuccess: React.FC = () => {
  const dispatch = useDispatch();
  const { requestId } = useSelector((state: ApplicationState) => state.cart);
  const { user } = useSelector((state: ApplicationState) => state.auth);

  if (user?.isAnonymous) {
    dispatch(AuthThunks.logout());
  }

  return (
    <Container css={cart.successWrapper}>
      <CartSuccessIcon />

      <h2 css={cart.successTitle}>
        <FormattedMessage id="cart.order-complete.title" />
      </h2>

      <p css={cart.successText}>
        <FormattedMessage id="cart.order-complete.content.1" />
        <br />
        <br />
        <FormattedMessage id="cart.order-complete.content.2" />
      </p>

      <h2 css={cart.successOfferText}>
        <FormattedMessage id="cart.order-number" />{' '}
        <span css={cart.successOfferNumber}>#{requestId}</span>
      </h2>

      <Link to="/" css={buttons.secondary.medium}>
        <FormattedMessage id="404.back-to-home" />
      </Link>
    </Container>
  );
};

export default CartSuccess;
